<template>
  <div class="extration-bdd-frais">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.prevent="openModal"
        >
          <font-awesome-icon icon="clipboard-check" />
        </div>
      </template>
      <span>Écriture comptable</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-header"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            Écriture comptable
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text
          class="body-card center-text mt-2 custom-expansion-ecriture-compta"
        >
          <!-- TABS  -->
          <v-tabs
            v-model="tab"
            align-with-title
            background-color="#f1edfb"
            color="#704ad1"
          >
            <v-tab :value="0">Comptabilisation facture</v-tab>
            <v-tab :value="1">Historique</v-tab>
          </v-tabs>
          <v-window v-model="tab">
            <!-- COMPTABILISATION FACTURE  -->
            <v-container fluid v-if="tab == 0">
              <v-form>
                <!-- SOCIETE LIST  -->
                <v-row class="row-top">
                  <v-col cols xs="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="filiale_id"
                      :items="getFiliaeOfResponsable"
                      item-text="name"
                      item-value="id"
                      color="#704ad1"
                      item-color="#704ad1"
                      class="mb-1"
                      placeholder="Séléctionnez"
                      :loading="isLoadingRes"
                      :persistent-placeholder="true"
                      label="Société"
                      required
                      dense
                      :auto-focus="false"
                      no-data-text="Aucune société trouvée"
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <!-- CLIENT  -->
                <v-row class="row-top">
                  <v-col cols xs="12" sm="12" md="12">
                    <v-autocomplete
                      color="#704ad1"
                      item-color="#704ad1"
                      v-model="client_id"
                      :items="computedGetVendeurs"
                      placeholder="Séléctionnez"
                      :persistent-placeholder="true"
                      label="Client"
                      item-text="text"
                      multiple
                      chips
                      return-object
                      outlined
                      :deletable-chips="true"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      item-value="value"
                      :small-chips="true"
                      :clearable="true"
                      :auto-focus="false"
                      no-data-text="Aucun client trouvé"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <!-- TYPE FACTURE  -->
                <v-row class="row-top">
                  <v-col cols xs="12" sm="12" md="12">
                    <v-autocomplete
                      color="#704ad1"
                      item-color="#704ad1"
                      v-model="type_facture"
                      :items="ListType"
                      placeholder="Séléctionnez"
                      :persistent-placeholder="true"
                      label="Type"
                      item-text="text"
                      multiple
                      chips
                      return-object
                      outlined
                      :deletable-chips="true"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      item-value="value"
                      :small-chips="true"
                      :clearable="true"
                      :auto-focus="false"
                      no-data-text="Aucun Type trouvé"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <!-- FAMILLE FACTURE  -->
                <v-row class="row-top">
                  <v-col cols xs="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="famille"
                      :items="familles"
                      color="#704ad1"
                      item-color="#704ad1"
                      class="mb-1"
                      placeholder="Séléctionnez"
                      :persistent-placeholder="true"
                      label="Famille"
                      required
                      dense
                      :auto-focus="false"
                      no-data-text="Aucune famille trouvée"
                      outlined
                      multiple
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="row-top">
                  <!-- DATE DEBUT -->
                  <v-col cols xs="6" sm="6" md="6" class="date-input-vuetify">
                    <v-menu
                      v-model="isDatePickerDebutVisible"
                      :close-on-content-click="false"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date_debut"
                          label="Date début"
                          outlined
                          readonly
                          hide-details
                          clearable
                          v-on="on"
                          v-bind="attrs"
                          :persistent-placeholder="true"
                          placeholder="Sélectionner un date"
                          prepend-inner-icon="mdi-calendar"
                          @click:clear="date_debut = null"
                          color="#704ad1"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="date_debut"
                        no-title
                        locale="fr"
                        color="#704ad1"
                        header-color="#704ad1"
                        event-color="704ad1"
                        required
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- DATE FIN -->
                  <v-col class="date-input-vuetify " cols xs="6" sm="6" md="6">
                    <v-menu
                      v-model="isDatePickerFinVisible"
                      :close-on-content-click="false"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date_fin"
                          label="Date fin"
                          outlined
                          readonly
                          hide-details
                          v-on="on"
                          v-bind="attrs"
                          clearable
                          :persistent-placeholder="true"
                          placeholder="Sélectionner un date"
                          prepend-inner-icon="mdi-calendar"
                          @click:clear="date_fin = null"
                          color="#704ad1"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date_fin"
                        no-title
                        locale="fr"
                        color="#704ad1"
                        header-color="#704ad1"
                        event-color="#704ad1"
                      ></v-date-picker> </v-menu></v-col
                ></v-row>
                <!-- NOM DE FICHIER A GENERE  -->
                <v-row class="row-top">
                  <v-col cols="12">
                    <v-text-field
                      label="Nom de fichier"
                      v-model="file_name"
                      :persistent-placeholder="true"
                      outlined
                      class="msg-error"
                      validate-on-blur
                      color="#704ad1"
                      placeholder="Entrer un nom pour le fichier"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- FORMAT DATE  -->
                <v-row class="margin-none">
                  <v-col cols="12" class="pt-0 ">
                    <v-select
                      :items="format_date_list"
                      item-text="text"
                      item-value="value"
                      v-model="format_date"
                      label="Format de la date"
                      outlined
                      dense
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      color="#704ad1"
                      item-color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Entrer un format pour la date"
                    ></v-select>
                    <!-- <v-text-field
                      label="Format de la date"
                      v-model="format_date"
                      :persistent-placeholder="true"
                      outlined
                      class="msg-error"
                      validate-on-blur
                      color="#704ad1"
                      placeholder="Entrer un format pour la date"
                    >
                    </v-text-field> -->
                  </v-col>
                </v-row>
                <!-- SEPARATEUR  -->
                <v-row class="margin-none">
                  <v-col cols="12" class=" ">
                    <v-radio-group
                      label="Séparateur des décimales"
                      inline
                      v-model="separator"
                      row
                    >
                      <v-radio
                        label="virgule"
                        :value="1"
                        class="radio-btn"
                        color="#704ad1"
                      ></v-radio>
                      <v-radio
                        label="point"
                        :value="0"
                        class="radio-btn"
                        color="#704ad1"
                      ></v-radio> </v-radio-group
                  ></v-col>
                </v-row>
                <!-- COMPTABILISE FACTURE  -->
                <v-row class="margin-none">
                  <v-col cols="6" class="pt-0">
                    <v-checkbox
                      label="Comptabiliser"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="true"
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      v-model="comptabilise"
                    ></v-checkbox> </v-col
                ></v-row> </v-form
            ></v-container>

            <!-- HISTORIQUE  -->
            <v-container fluid v-if="tab == 1">
              <v-progress-circular
                v-if="loadingHistorique"
                indeterminate
                class="ml-2"
                color="#704ad1"
              ></v-progress-circular>
              <div v-else>
                <div
                  class="mt-4"
                  v-if="
                    getHistoriqueComptabilisationFacture &&
                      getHistoriqueComptabilisationFacture.length
                  "
                >
                  <v-row
                    v-for="(hist,
                    index) in getHistoriqueComptabilisationFacture"
                    :key="index"
                    class="flex-div"
                  >
                    <v-col cols="4" class="">
                      <div>
                        {{ hist.file_name }}
                      </div></v-col
                    >
                    <div class="">-</div>
                    <v-col cols="5" class="">
                      <div>
                        {{ hist.created_at }}
                      </div>
                    </v-col>
                    <v-col cols="2" class="" style="text-align: initial">
                      <div
                        class="block-icon-table-facture ml-1 mr-1 pt-0 pb-0 pl-1 pr-1"
                        @click.prevent="downloadFichier(hist)"
                        title="Télécharger fichier"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'circle-down']"
                        /></div
                    ></v-col>
                  </v-row>
                </div>
                <div v-else>
                  <p class="empty-text mt-6">
                    Il n'y a aucune historique à afficher
                  </p>
                </div>
              </div>
            </v-container>
          </v-window>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="tab == 0"
            color="#704ad1"
            text
            @click="handleComptabilise"
            :loading="loading"
            :class="{ loader: loading }"
            type="submit"
          >
            Génère le fichier comptable
          </v-btn>
          <v-btn text @click="hideModal">
            {{ tab == 0 ? 'Annuler' : 'Fermer' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    methods: { required: true }
  },
  data() {
    return {
      loading: false,
      error: [],
      dialog: false,
      tab: 0,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      famille: [],
      date_debut: null,
      date_fin: null,
      comptabilise: false,
      file_name: null,
      loadingHistorique: false,
      filiale_id: null,
      isLoadingRes: false,
      format_date: 'd/m/Y',
      separator: 0,
      format_date_list: [
        { text: 'jj/mm/aaaa', value: 'd/m/Y' },
        { text: 'jj-mm-aaaa', value: 'dd-m-Y' },
        { text: 'aaaa/mm/jj', value: 'Y/m/d' },
        { text: 'aaaa-mm-jj', value: 'Y-m-d' }
      ],
      client_id: [],
      type_facture: [],
      ListType: [
        { value: 'Facture', text: 'Facture' },
        { value: "Facture d'avoir", text: "Facture d'avoir" }
      ]
    };
  },
  methods: {
    ...mapActions([
      'historiqueComptabilisationFacture',
      'exportFileInListExporte',
      'getFilialsOfConnectedResponsable'
    ]),
    async openModal() {
      this.dialog = true;
      this.loadingHistorique = true;
      await this.historiqueComptabilisationFacture();
      this.loadingHistorique = false;
    },
    async handleComptabilise() {
      this.error = null;
      if (this.date_debut == null) {
        this.error = 'Date début obligatoire';
      } else if (this.filiale_id == null) {
        this.error = 'Sociéte obligatoire';
      } else if (this.file_name == null) {
        this.error = 'Nom de fichier obligatoire';
      } else {
        this.loading = true;
        let payload = {};
        if (this.famille.length) {
          payload.famille = this.famille;
        }
        if (this.client_id.length) {
          payload.client_id = this.client_id.map(i => i.value);
        }
        if (this.type_facture.length) {
          payload.type_facture = this.type_facture.map(i => i.value);
        }
        if (this.date_fin) {
          payload.date_fin = this.date_fin;
        }
        if (this.date_debut) {
          payload.date_debut = this.date_debut;
        }
        if (this.comptabilise) {
          payload.comptabilise = this.comptabilise;
        }
        if (this.file_name) {
          payload.file_name = this.file_name;
        }
        if (this.filiale_id) {
          payload.filiale_id = this.filiale_id;
        }
        if (this.format_date) {
          payload.format_date = this.format_date;
        }
        payload.separator = this.separator;

        const response = await this.methods(payload);
        if (response) {
          this.loading = false;
          this.dialog = false;
          let message = this.comptabilise
            ? 'Vos factures est comptabilisés et votre fichier sera bientôt prêt !'
            : 'Votre fichier sera bientôt prêt !';
          this.$swal.fire({
            title: message,
            type: 'confirm',
            icon: 'success',
            locale: 'fr',
            stopKeydownPropagation: false,
            confirmButtonClass: 'btn btn-success-alert',
            cancelButtonClass: 'btn btn-danger-alert',
            cancelButtonColor: '',
            confirButtonColor: '',
            customClass: {
              actions: 'my-actions',
              cancelButton: 'order-2 ',
              confirmButton: 'order-1'
            }
          });
          if (this.comptabilise) {
            this.$emit('refreshData');
          }
          this.resetModal();
        } else {
          this.loading = false;
          this.error = 'Une erreur est survenue';
        }
      }
    },
    downloadFichier(hist) {
      this.exportFileInListExporte(hist);
    },
    hideModal() {
      this.dialog = false;
      this.resetModal();
      // this.$emit('end', false);
    },
    resetModal() {
      this.loading = false;
      this.error = [];
      this.famille = [];
      this.client_id = [];
      this.type_facture = [];
      this.date_debut = null;
      this.date_fin = null;
      this.comptabilise = false;
      this.file_name = null;
      this.tab = 0;
      this.filiale_id = null;
      this.separator = 0;
      this.format_date = 'd/m/Y';
    }
  },
  computed: {
    ...mapGetters([
      'familles',
      'getHistoriqueComptabilisationFacture',
      'getFiliaeOfResponsable'
    ]),
    computedGetVendeurs() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    }
  },
  components: {},
  async mounted() {
    this.isLoadingRes = true;
    await this.getFilialsOfConnectedResponsable();
    this.isLoadingRes = false;
  }
};
</script>
<style lang="scss">
.custom-expansion-ecriture-compta {
  .v-select .v-label,
  .v-input--checkbox .v-label {
    font-size: 12px;
  }
  .radio-btn {
    .v-label {
      font-size: 13px !important;
      align-self: self-start;
    }
  }
}
</style>
